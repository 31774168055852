import { Config } from '../../Config';
import { GalaAttendeeFields } from '../../models/galaGroup/galaAttendee/galaAttendeeFields';
import { IGalaAttendee, IGalaAttendeeResponse, IGalaAttendeesResponse, IGalaAttendeeWithTokResponse, IPaymentMethodsResponse, IValidResponse } from '../../models/galaGroup/galaAttendee/iGalaAttendee';
import { getFromStorage, removeKeyFromStorage, setToStorage } from '../../services/IonicStorage';
import { AxiosController } from '../AxiosController';
import { PathEnum } from '../PathEnum';

export class GalaAttendeeController extends AxiosController {
    private PATH: string = `${Config.REMOTE_SERVER_ADDR}${PathEnum.GALAATTENDEE}`;

    /**
     * Generates an OTP code set to the
     * provided phone number
     * @param phone : string
     * @returns IValidResponse
     */
    public async getCode(
        phone: string,
    ): Promise<IValidResponse> {
        const validResponse = await this.axiosPOST<IValidResponse>(
            `${this.PATH}/getCode`,
            {
                [GalaAttendeeFields.phone]: phone,
            }
        );

        return validResponse;
    }

    /**
     * Verifies OTP code against provided
     * phone number and sets galaAttendee in
     * localStorage
     * @param phone : string
     * @param code : string
     * @returns IGalaAttendeeWithTokResponse
     */
    public async login(
        phone: string,
        code: string,
        setGalaAttendee: (galaAttendee: IGalaAttendee) => void,
    ): Promise<IGalaAttendeeWithTokResponse> {
        const galaAttendeeResponse = await this.axiosPOST<IGalaAttendeeWithTokResponse>(
            `${this.PATH}/login`,
            {
                [GalaAttendeeFields.phone]: phone,
                [GalaAttendeeFields.code]: code,
            }
        );

        await setToStorage(GalaAttendeeFields.galaAttendeeTok, galaAttendeeResponse.data.galaAttendeeTok);
        await setToStorage(GalaAttendeeFields.galaAttendee, galaAttendeeResponse.data.galaAttendee);
        setGalaAttendee(galaAttendeeResponse.data.galaAttendee);
        return galaAttendeeResponse;
    }

    public async checkIn(
        galaAttendeeId: string,
    ): Promise<any> {
        await this.axiosPOST<any>(
            `${Config.REMOTE_SERVER_ADDR}/gala/live/checkIn`,
            {
                [GalaAttendeeFields.galaAttendeeId]: galaAttendeeId,

            }
        );
    }

    /**
     * Verifies galaAttendeeId against galaAttendee
     * @param galaAttendeeId : string
     * @returns IGalaAttendeeResponse
     */
    public async verify(
        galaAttendeeId: string,
    ): Promise<IGalaAttendeeResponse> {
        const galaAttendeeResponse = await this.axiosPOST<IGalaAttendeeResponse>(
            `${this.PATH}/verify`,
            {
                [GalaAttendeeFields.galaAttendeeId]: galaAttendeeId,
            },
            true
        );

        return galaAttendeeResponse;
    }

    public async getPaymentMethods(
        galaAttendeeId: string,
    ): Promise<IPaymentMethodsResponse> {
        const paymentMethodsResponse = await this.axiosGET<IPaymentMethodsResponse>(
            `${this.PATH}/paymentMethods`,
            {
                [GalaAttendeeFields.galaAttendeeId]: galaAttendeeId,
            },
            true,
        );

        return paymentMethodsResponse;
    }

    public async getByParams(
        ticketNum: string,
        fName: string,
        lName: string,
        paddleNum: string,
    ): Promise<IGalaAttendeesResponse> {
        const paymentMethodsResponse = await this.axiosGET<IGalaAttendeesResponse>(
            `${this.PATH}/params`,
            {
                params: {
                    ticketNum: parseInt(ticketNum, 10),
                    fName: fName,
                    lName: lName,
                    paddleNum: parseInt(paddleNum, 10),
                },
            },
        );

        return paymentMethodsResponse;
    }

    /**
     * Gets galaAttendee from Ionic Storage
     * @returns IGalaAttendee | null
     */
    public async getGalaAttendee(): Promise<IGalaAttendee | null> {
        const user: IGalaAttendee | null = await getFromStorage(GalaAttendeeFields.galaAttendee);
        return user;
    }

    public async logout(
        setGalaAttendee: (galaAttendee: null) => void
    ): Promise<void> {
        setGalaAttendee(null);
        await removeKeyFromStorage(GalaAttendeeFields.galaAttendeeTok);
        await removeKeyFromStorage(GalaAttendeeFields.galaAttendee);
    }


}
