import { IonContent, IonPage } from '@ionic/react';
import { useState } from 'react';
import mascotImg from '../../assets/mascot.png';
import AttendeeSearch from '../../components/AttendeeSearch/AttendeeSearch';
import { Factory } from '../../controllers/ControllerFactory';
import { IGalaAttendee } from '../../models/galaGroup/galaAttendee/iGalaAttendee';
import './Attendee.scss';

const Attendee: React.FC = () => {

  const [galaAttendees, setGalaAttendees] = useState<IGalaAttendee[]>([]);
  const [checkedInArr, setCheckedInArr] = useState<string[]>([]);
  const galaAttendeeController = Factory.GalaAttendeeController;
  const handleCheckIn = async (attendeeId: string) => {
    try {
      await galaAttendeeController.checkIn(attendeeId);
      setCheckedInArr(checkedInArr => [...checkedInArr, attendeeId]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen className="sponsorsContent">
        <div className="container sponsorsContainer">
          <img src={mascotImg} alt="mascot" className="mascot" />
          <div className="content">
            <header>
              Attendee Information
            </header>
            <AttendeeSearch setGalaAttendees={setGalaAttendees} setCheckedInArr={setCheckedInArr} />
            {galaAttendees.length > 0 && (
              <div className="searchResults">
                <h2>Search Results</h2>
                {galaAttendees.map((galaAttendee: IGalaAttendee) => (
                  <div className="attendee">
                    {galaAttendee.registered && (
                      <>
                        <h3>{galaAttendee.fName} {galaAttendee.lName} - {galaAttendee.phone}</h3>
                        <p>
                          <span>Table Number</span>
                          <span>{galaAttendee.tableName}</span>
                        </p>
                        <p>
                          <span>Paddle Number</span>
                          <span>{galaAttendee.ticketNum}</span>
                        </p>
                        <p>
                          <span>Ticket Number</span>
                          <span>{galaAttendee.ticketNum}</span>
                        </p>
                        {!checkedInArr.includes(galaAttendee._id) && (
                          <button onClick={() => handleCheckIn(galaAttendee._id)}>Check In</button>
                        )}
                      </>
                    )}
                    {!galaAttendee.registered && (
                      <div className="registerNow">
                        <h4>#{galaAttendee.ticketNum}</h4>
                        <a href={`https://sdcfund.org/redeem/${galaAttendee._id}`}>register</a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Attendee;
