/* eslint-disable react-hooks/exhaustive-deps */
import { IonModal, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import backIcon from '../../assets/back.svg';
import closeIcon from '../../assets/close.svg';
import mascotImg from '../../assets/mascot.png';
import { Factory } from '../../controllers/ControllerFactory';
import { IGalaAttendee } from '../../models/galaGroup/galaAttendee/iGalaAttendee';
import { AuctionStatusEnum } from '../../models/galaGroup/galaAuction/galaAuctionFields';
import { IGalaAuction } from '../../models/galaGroup/galaAuction/iGalaAuction';
import { amountToString } from '../../utils/global';
import './Auction.scss';

interface AuctionProps {
  galaAttendee: IGalaAttendee;
  closeModal: () => void;
}

const Auction: React.FC<AuctionProps> = ({
  galaAttendee,
  closeModal,
}) => {

  const galaAuctionController = Factory.GalaAuctionController;
  const [presentAlert] = useIonAlert();
  const [galaAuctions, setGalaAuctions] = useState<IGalaAuction[]>([]);
  const [galaAuction, setGalaAuction] = useState<IGalaAuction | null>(null);
  const [uniqueLabel, setUniqueLabel] = useState('');
  const changeUniqueLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setUniqueLabel(val);
  }



  const [bid, setBid] = useState(0);
  const changeBid = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const split = val.split('.');
    if (split.length === 2 && split[1].length > 2) {
      e.preventDefault();
      return;
    }
    setBid(parseFloat(val));
  }


  const get = async (auctionId: string) => {
    const getResp = await galaAuctionController.get(auctionId);
    setGalaAuction(getResp.data.galaAuction);
    setBid((getResp.data.galaAuction.currPrice + getResp.data.galaAuction.minIncrease) / 100)
  }

  const getByUniqueLabel = async () => {
    if (uniqueLabel.length === 0) return;
    const auctionResp = await galaAuctionController.getByUniqueLabel(uniqueLabel);
    setGalaAuction(auctionResp.data.galaAuction);
    setBid((auctionResp.data.galaAuction.currPrice + auctionResp.data.galaAuction.minIncrease) / 100);
  }

  const notEnoughBid = galaAuction !== null && (bid * 100 < galaAuction.currPrice + galaAuction.minIncrease)
  const placeBid = async () => {
    if (galaAuction === null || galaAttendee === null) return;
    if (notEnoughBid) {
      console.log('not enough');
      return;
    }

    try {
      const galaAuctionResp = await galaAuctionController.placeBid(
        galaAuction._id, galaAttendee._id, bid * 100
      );
      setGalaAuction(galaAuctionResp.data.galaAuction);
      setBid((galaAuctionResp.data.galaAuction.currPrice + galaAuctionResp.data.galaAuction.minIncrease) / 100);
    } catch (err) {
      console.log(err);
      get(galaAuction._id)
    }
  }

  const showConfirm = () => {
    if (notEnoughBid) return;
    presentAlert({
      header: `Bid $${(bid)}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          role: 'confirm',
          handler: () => {
            placeBid();
          },
        },
      ],
    })
  }

  const getAll = async () => {
    const allResp = await galaAuctionController.getAll();
    setGalaAuctions(allResp.data.galaAuctions)
  }

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    getAll();
  }, [galaAuction]);

  const renderAuctions = () => (
    <div className="auctions">
      {galaAuctions.map((auction) => (
        <div className={`auction ${auction.auctionStatus !== AuctionStatusEnum.open ? 'invalid' : null}`} onClick={() => get(auction._id)}>
          {auction.imgUrl && <img src={auction.imgUrl} alt={auction.name.en} />}

          <div className="info">
            <h3>{auction.name.en}</h3>
            <p>
              <span>Current Price:</span>
              <span>{amountToString(auction.currPrice)}</span>
            </p>
            <p>
              <span>Starting Price:</span>
              <span>{amountToString(auction.startPrice)}</span>
            </p>
            <p>
              <span>Minimum Increase:</span>
              <span>{amountToString(auction.minIncrease)}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  )

  const search = () => {
    getByUniqueLabel();
  }

  return (
    <>
      <IonModal isOpen={galaAuction !== null} className="auctionModal">
        <header>
          <img src={backIcon} alt="back" onClick={() => setGalaAuction(null)} />
          Silent Auction
        </header>
        {galaAuction !== null && (
          <div className="auctionItem">
            {galaAuction.imgUrl !== null && (
              <img src={galaAuction.imgUrl} alt="auctionItem" />
            )}
            <h3>{galaAuction.name.en}</h3>
            <p>
              <span>Current Price:</span>
              <span>{amountToString(galaAuction.currPrice)}</span>
            </p>
            <p>
              <span>Starting Price:</span>
              <span>{amountToString(galaAuction.startPrice)}</span>
            </p>
            <p>
              <span>Minimum Increase:</span>
              <span>{amountToString(galaAuction.minIncrease)}</span>
            </p>
            <div className={`bidContainer ${galaAuction.auctionStatus !== AuctionStatusEnum.open ? 'invalid' : null}`}>
              <div className="inputWrapper">
                <input type="number" placeholder="Bid Amount" value={bid} onChange={changeBid} />
              </div>

              <button onClick={showConfirm}>
                {notEnoughBid ? 'Enter Valid Amount' : 'place bid'}
              </button>
            </div>
          </div>
        )}

      </IonModal>
      <div className="auctionContent">
        <div className="auctionContainer">

          <img src={mascotImg} alt="mascot" className="mascot" />
          <div className="content">
            <header>
              Silent Auction
              <img src={closeIcon} alt="close" className="close" onClick={closeModal} />
            </header>
            <h4>{galaAttendee.ticketNum} - {galaAttendee.fName} {galaAttendee.lName}</h4>
            {renderAuctions()}
            <div className="auctionSearch">
              <div className="inputWrap">
                <input placeholder="Auction #" value={uniqueLabel} onChange={changeUniqueLabel} />
              </div>
              <button onClick={search}>search</button>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Auction;
