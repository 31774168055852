import React, { useState } from 'react';
import { Factory } from '../../controllers/ControllerFactory';
import { IGalaAttendee } from '../../models/galaGroup/galaAttendee/iGalaAttendee';
import './AttendeeSearch.scss';

interface AttendeeSearchProps {
    setGalaAttendees: (tGalaAttendees: IGalaAttendee[]) => void;
    setCheckedInArr?: (ids: string[]) => void;
}

const AttendeeSearch: React.FC<AttendeeSearchProps> = ({
    setGalaAttendees, setCheckedInArr
}) => {
    const [ticketNum, setTicketNum] = useState<any>('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [paddleNum, setPaddleNum] = useState<any>('');

    const galaAttendeeController = Factory.GalaAttendeeController;

    const handleSearch = async () => {
        try {
            const galaAttendeesResponse = await galaAttendeeController.getByParams(
                ticketNum,
                fName,
                lName,
                paddleNum,
            )
            setGalaAttendees(galaAttendeesResponse.data.galaAttendees);
            if (setCheckedInArr) {
                setCheckedInArr(galaAttendeesResponse.data.checkedIn);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const changeFName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setFName(val);
    }

    const changeLName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setLName(val);
    }

    const changeTicketNum = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setTicketNum(parseInt(val, 10));
    }

    const changePaddleNum = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setPaddleNum(parseInt(val, 10));
    }

    return (
        <div className="attendeeSearch">
            <header>Search for Attendee</header>
            <div className="inputWrapper">
                <input value={fName} onChange={changeFName} placeholder="First Name" />
                <input value={lName} onChange={changeLName} placeholder="Last Name" />
                <input type="number" value={ticketNum} inputMode="numeric" onChange={changeTicketNum} placeholder="Ticket Number" />
                <input type="number" value={paddleNum} inputMode="numeric" onChange={changePaddleNum} placeholder="Paddle Number" />
            </div>

            <button onClick={handleSearch}>search</button>
        </div>
    );
};

export default AttendeeSearch;
