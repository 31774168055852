import { IonContent, IonModal, IonPage } from '@ionic/react';
import { useState } from 'react';
import mascotImg from '../../assets/mascot.png';
import AttendeeSearch from '../../components/AttendeeSearch/AttendeeSearch';
import { IGalaAttendee } from '../../models/galaGroup/galaAttendee/iGalaAttendee';
import Auction from './Auction';
import './AuctionView.scss';

const AuctionView: React.FC = () => {

    const [galaAttendees, setGalaAttendees] = useState<IGalaAttendee[]>([]);

    const [galaAttendee, setGalaAttendee] = useState<IGalaAttendee | null>(null);

    return (
        <IonPage>
            <IonModal isOpen={galaAttendee !== null}>
                {galaAttendee !== null && (
                    <Auction galaAttendee={galaAttendee} closeModal={() => setGalaAttendee(null)} />
                )}
            </IonModal>
            <IonContent fullscreen className="auctionViewContent">
                <div className="container auctionViewContainer">
                    <img src={mascotImg} alt="mascot" className="mascot" />
                    <div className="content">
                        <header>
                            Auction
                        </header>
                        <AttendeeSearch setGalaAttendees={setGalaAttendees} />
                        {galaAttendees.length > 0 && (
                            <div className="searchResults">
                                <h2>Search Results</h2>
                                {galaAttendees.map((galaAttendee: IGalaAttendee) => (
                                    <>

                                        {galaAttendee.registered && (
                                            <div className="attendee" onClick={() => setGalaAttendee(galaAttendee)}>
                                                <div>
                                                    <h3>{galaAttendee.fName} {galaAttendee.lName} - {galaAttendee.phone}</h3>
                                                    <p>
                                                        <span>Table Number</span>
                                                        <span>{galaAttendee.tableName}</span>
                                                    </p>
                                                    <p>
                                                        <span>Paddle Number</span>
                                                        <span>{galaAttendee.ticketNum}</span>
                                                    </p>
                                                    <p>
                                                        <span>Ticket Number</span>
                                                        <span>{galaAttendee.ticketNum}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}

                                        {!galaAttendee.registered && (
                                            <div className="attendee">
                                                <div className="registerNow">
                                                    <h4>#{galaAttendee.ticketNum}</h4>
                                                    <a href={`https://sdcfund.org/redeem/${galaAttendee._id}`}>register</a>
                                                </div>
                                            </div>
                                        )}

                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default AuctionView;
