import { IonContent, IonPage } from '@ionic/react';
import { useContext } from 'react';
import { Factory } from '../../controllers/ControllerFactory';
import { StaffContext, StaffContextType } from '../../services/StaffContext';
import mascotImg from '../../assets/mascot.png';
import welcomeImg from '../../assets/welcome.svg';
import './Home.scss';

const Home: React.FC = () => {
  const staffController = Factory.StaffController;
  const { staff, setStaff } = useContext(StaffContext) as StaffContextType;

  const handleLogout = () => {
    staffController.logout(setStaff);
  }
  return (
    <IonPage>
      <IonContent fullscreen className="homeContent">

        <div className="container homeContainer">
          <img src={mascotImg} alt="mascot" className="mascot" />
          <div className="content">
            <img src={welcomeImg} alt="Presenting" className="welcome" />
            <h1>Welcome {staff!.fName} {staff!.lName},</h1>
            <h2><a href="http://google.com">Link To Schedule</a></h2>
            <button onClick={handleLogout}>Logout</button>
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
